export function dataInJs() {
	var rows = {
		//出货方式
		shippingMethod:[{
			name:'pcs（单片）',
			enName:"Pcs (single chip)",
			id:1,
			type:true
		},
		{
			name:'set（连片）',
			enName:"Set (contiguous)",
			id:1,
			type:true
		}],
		bakingTime:[{
			name:'12小时',
			enName:"12 Hour",
			type:true
		},
		{
			name:'24小时',
			enName:"24 Hour",
			type:true
		},
		{
			name:'48小时',
			enName:"48 Hour",
			type:true
		}],
		numberBoards:[{
			name:25,
			enName:25,
			type:true
		}],
		numberBoardLayers:[{
			name:'1层',
			enName:"1 layer",
			type:true
		},
		{
			name:'2层',
			enName:"2 layer",
			type:true
		},
		{
			name:'4层',
			enName:"4 layer",
			type:true
		},
		{
			name:'6层',
			enName:"6 layer",
			type:true
		},
		{
			name:'8层',
			enName:"8 layer",
			type:true
		},
		{
			name:'10层',
			enName:"10 layer",
			type:true
		},
		{
			name:'12层',
			enName:"12 layer",
			type:true
		},
		{
			name:'14层',
			enName:"14 layer",
			type:true
		}],
		quantity:[{
			name:'25',
			enName:'25',
		}],
		plateType:[{
			name:'FR-4',
			enName:'FR-4',
			image:require('@/assets/img/pcb-fr4@icon.png'),
			type:true
		},
		// {
		// 	name:'铝基板',
		// 	image:require('@/assets/img/pcb-ljb@icon.png')
		// },
		// {
		// 	name:'Rogers',
		// 	image:require('@/assets/img/pcb-rogers@icon.png')
		// },
		// {
		// 	name:'铜基板',
		// 	image:require('@/assets/img/pcb-tjb@icon.png')
		// },
		// {
		// 	name:'刚挠结合板',
		// 	image:require('@/assets/img/pcb-gn@icon.png')
		// },
		// {
		// 	name:'CEM-1',
		// 	image:require('@/assets/img/pcb-cem-1@icon.png')
		// }
		],
		plateTG:[{
			name:'国纪130',
			enName:"TG 130",
			type:true
		},{
			name:'建滔150',
			enName:"KB 150",
			type:true
		},
		{
			name:'建滔170',
			enName:"KB 170",
			type:true
		},
		{
			name:'生益150',
			enName:"Shenyi 150",
			type:true
		},
		{
			name:'生益170',
			enName:"Shenyi 170",
			type:true
		},
		{
			name:'国纪120',
			enName:"TG 120",
			type:true
		}],
		resistanceWeldingColor:[{
			name:'绿色',
			enName:"Green",
			color:'#008000',
			type:true
		},
		{
			name:'红色',
			enName:"Red",
			color:'#bb1407',
			type:true
		},
		{
			name:'黄色',
			enName:"Yellow",
			color:'#ffd200',
			type:true
		},
		{
			name:'白色',
			enName:"White",
			color:'#fff',
			type:true
		},
		{
			name:'黑色',
			enName:"Black",
			color:'#000',
			type:true
		},
		{
			name:'紫色',
			enName:"Purple",
			color:'#a20495',
			type:true
		},
		{
			name:'哑光绿',
			enName:"Matte green",
			color:'-webkit-linear-gradient(top, #218626, #58de5f)',
			type:true
		},
		{
			name:'哑光黑',
			enName:"Matte black",
			color:'#000',
			type:true
		},
		{
			name:'白油(太阳WT03)',
			enName:"White oil (Sun WT03)",
			color:'#fff',
			type:true
		},
		{
			name:'白油(积水B2-R603)',
			enName:"White oil (standing water B2-R603)",
			color:'#fff',
			type:true
		},
		{
			name:'白油(积水B2-R802)ST',
			enName:"White oil (standing water B2-R802) ST",
			color:'#fff',
			type:true
		},
		{
			name:'白油(美开美LE600 XZ)',
			enName:"White oil (Meikaimei LE600 XZ)",
			color:'#fff',
			type:true
		},
		{
			name:'无',
			enName:"Not",
			color:null,
			type:true
		}],
		boardThickness:[{
			name:'0.2',
			enName:'0.2',
			type:true
		},
		{
			name:'0.3',
			enName:'0.3',
			type:true
		},
		{
			name:'0.4',
			enName:'0.4',
			type:true
		},
		{
			name:'0.6',
			enName:'0.6',
			type:true
		},
		{
			name:'0.8',
			enName:'0.8',
			type:true
		},
		{
			name:'1',
			enName:'1',
			type:true
		},
		{
			name:'1.2',
			enName:'1.2',
			type:true
		},
		{
			name:'1.6',
			enName:'1.6',
			type:true
		},
		{
			name:'2',
			enName:'2',
			type:true
		},
		{
			name:'2.4',
			enName:'2.4',
			type:true
		},
		{
			name:'1.4',
			enName:'1.4',
			type:true
		},
		{
			name:'1.8',
			enName:'1.8',
			type:true
		},
		{
			name:'2.8',
			enName:'2.8',
			type:true
		},
		{
			name:'3',
			enName:'3',
			type:true
		},
		{
			name:'3.2',
			enName:'3.2',
			type:true
		},
		{
			name:'3.6',
			enName:'3.6',
			type:true
		},
		{
			name:'3.8',
			enName:'3.8',
			type:true
		}],
		copperThickness:[{
			name:'1/1oz',
			enName:'1/1oz',
			type:true
		},
		{
			name:'2/2oz',
			enName:'2/2oz',
			type:true
		},
		{
			name:'3/3oz',
			enName:'3/3oz',
			type:true
		},
		{
			name:'4/4oz',
			enName:'4/4oz',
			type:true
		},
		{
			name:'5/5oz',
			enName:'5/5oz',
			type:true
		},
		{
			name:'6/6oz',
			enName:'6/6oz',
			type:true
		},
		{
			name:'7/7oz',
			enName:'7/7oz',
			type:true
		},
		{
			name:'8/8oz',
			enName:'8/8oz',
			type:true
		},
		{
			name:'9/9oz',
			enName:'9/9oz',
			type:true
		},
		{
			name:'10/10oz',
			enName:'10/10oz',
			type:true
		}],
		minimumLineWidth:[{
			name:'3/3mil',
			enName:'3/3mil',
			type:true
		},
		{
			name:'4/4mil',
			enName:'4/4mil',
			type:true
		},
		{
			name:'5/5mil',
			enName:'5/5mil',
			type:true
		},
		{
			name:'6/6mil',
			enName:'6/6mil',
			type:true
		}],
		minimumThroughHole:[{
			name:'0.15mm',
			enName:'0.15mm',
			type:true
		},
		{
			name:'0.2mm',
			enName:'0.2mm',
			type:true
		},
		{
			name:'0.25mm',
			enName:'0.25mm',
			type:true
		},
		{
			name:'0.3mm',
			enName:'0.3mm',
			type:true
		}],
		characterColor:[{
			name:'白色',
			enName:"White",
			color:'#fff',
			type:true
		},
		{
			name:'黑色',
			enName:"Black",
			color:'#000',
			type:true
		},
		{
			name:'黄色',
			enName:"Yellow",
			color:'#ffd200',
			type:true
		},
		{
			name:'无',
			enName:"Not",
			color:null,
			type:true
		}],
		solderPadSprayCoating:[{
			name:'有铅喷锡',
			enName:"Lead sprayed tin",
			value:null,
			type:true,
			list:[]
		},
		{
			name:'无铅喷锡',
			enName:"lead free",
			value:null,
			type:true,
			list:[]
		},
		{
			name:'沉金',
			enName:"Trumeric",
			value:0,
			type:true,
			list:[{
				name:'1U',
				enName:'1U',
			},
			{
				name:'2U 推荐',
				enName:'2U recommended',
			},
			{
				name:'3U',
				enName:'3U',
			}]
		},
		{
			name:'OSP',
			enName:"OSP",
			value:null,
			type:true,
			list:[]
		},
		{
			name:'裸铜',
			enName:"Trumeric",
			value:null,
			type:true,
			list:[]
		},
		{
			name:'沉锡 (厚度0.8-1.2UM)',
			enName:'Sinking tin (thickness 0.8-1.2UM)',
			value:null,
			type:true,
			list:[]
		},
		{
			name:'沉银',
			enName:'Immersion silver',
			value:0,
			type:true,
			list:[{
				name:'6U',
				enName:'6U',
			},
			{
				name:'7U',
				enName:'7U',
			},
			{
				name:'8U',
				enName:'8U',
			},
			{
				name:'9U',
				enName:'9U',
			},
			{
				name:'10U',
				enName:'10U',
			},
			{
				name:'11U',
				enName:'11U',
			},
			{
				name:'12U',
				enName:'12U',
			}]
		},
		{
			name:'镀硬金',
			enName:'Hard gold plating',
			value:0,
			type:true,
			list:[{
				name:'AU:3U"/NI:120U"',
				enName:'AU:3U"/NI:120U"',
			},
			{
				name:'AU:5U"/NI:120U"',
				enName:'AU:5U"/NI:120U"',
			},
			{
				name:'AU:10U"/NI:120U"',
				enName:'AU:10U"/NI:120U"',
			},
			{
				name:'AU:15U"/NI:120U"',
				enName:'AU:15U"/NI:120U"',
			},
			{
				name:'AU:20U"/NI:120U"',
				enName:'AU:20U"/NI:120U"',
			},
			{
				name:'AU:25U"/NI:120U"',
				enName:'AU:25U"/NI:120U"',
			},
			{
				name:'AU:30U"/NI:120U"',
				enName:'AU:30U"/NI:120U"',
			}]
		},
		{
			name:'无铅喷锡+选择性沉金',
			enName:'Lead free tin spraying + selective gold deposition',
			value:0,
			type:true,
			list:[{
				name:'1U',
				enName:'1U',
			},
			{
				name:'2U',
				enName:'2U',
			},
			{
				name:'3U',
				enName:'3U',
			}]
		},
		{
			name:'无铅喷锡+选择性电金',
			enName:'Lead-free tin spraying + selective electrocautery',
			value:0,
			type:true,
			list:[{
				name:'AU:3U"/NI:120U"',
				enName:'AU:3U"/NI:120U"',
			},
			{
				name:'AU:5U"/NI:120U"',
				enName:'AU:5U"/NI:120U"',
			},
			{
				name:'AU:10U"/NI:120U"',
				enName:'AU:10U"/NI:120U"',
			},
			{
				name:'AU:15U"/NI:120U"',
				enName:'AU:15U"/NI:120U"',
			},
			{
				name:'AU:20U"/NI:120U"',
				enName:'AU:20U"/NI:120U"',
			},
			{
				name:'AU:25U"/NI:120U"',
				enName:'AU:25U"/NI:120U"',
			},
			{
				name:'AU:30U"/NI:120U"',
				enName:'AU:30U"/NI:120U"',
			}]
		},
		{
			name:'沉金+选择性OSP',
			enName:'Gold sinking + selective OSP',
			value:0,
			type:true,
			list:[{
				name:'1U',
				enName:'1U',
			},
			{
				name:'2U',
				enName:'2U',
			},
			{
				name:'3U',
				enName:'3U',
			}]
		},
		{
			name:'化镍钯金',
			enName:'Nickel palladium gold',
			value:0,
			type:true,
			list:[{
				name:'Ni:200U"Pd:1U"Au:1U"',
				enName:'Ni:200U"Pd:1U"Au:1U"',
			},
			{
				name:'Ni:200U"Pd:2U"Au:2U"',
				enName:'Ni:200U"Pd:2U"Au:2U"',
			},
			{
				name:'Ni:200U"Pd:3U"Au:3U"',
				enName:'Ni:200U"Pd:3U"Au:3U"',
			},
			{
				name:'Ni:200U"Pd:4U"Au:4U"',
				enName:'Ni:200U"Pd:4U"Au:4U"',
			}]
		}],
		resistanceWeldingCoverage:[{
			name:'过孔盖油',
			enName:'Through-hole cover oil',
			type:true
		},
		{
			name:'过孔塞油',
			enName:'Through-hole plug oil',
			type:true
		},
		{
			name:'过孔开窗',
			enName:'Through hole window opening',
			type:true
		}],
		text:[{
			name:'飞针全测（打样免费测试）',
			enName:'Full testing with flying needles (free testing with samples)',
			type:true
		},
		{
			name:'飞针抽测（免费AOI光学扫描+飞针抽测）',
			enName:'Flying needle sampling (free AOI optical scanning+flying needle sampling)',
			type:true
		},
		{
			name:'测试架电测（免费AOI光学扫描+测试架测试）,速度快,一次性费用,返单不收费。测试架费用预计600-800元,以审核后为准。',
			enName:'Test bench electrical testing (free AOI optical scanning+test bench testing), fast speed, one-time cost, no charge for return orders. The estimated cost of the testing rack is 600-800 yuan, subject to review.',
			type:true
		}],
		packagingRequirements:[{
			name:'标识包装',
			enName:'logo packaging',
			type:true
		},
		{
			name:'中性包装',
			enName:'Neutral packaging',
			type:true
		}],
		addOrderNumber:[{
			name:'增加订单编号',
			enName:'Add order number',
			type:true
		},
		{
			name:'指定位置添加订单编号',
			enName:'Add order number at specified location',
			type:true
		},
		{
			name:'不添加订单编号',
			enName:'Do not add order number',
			type:true
		}],
		dateManufacture:[{
			name:'不需要',
			enName:'Not required',
			type:true
		},
		{
			name:'添加我司生产日期',
			enName:'Add our production date',
			type:true
		},
		{
			name:'更新文件设计日期',
			enName:'Update file design date',
			type:true
		}],
		ULidentification:[{
			name:'不需要',
			enName:'Not required',
			type:true
		},
		{
			name:'需要',
			enName:'Need',
			type:true
		}],
		productionDraft:[{
			name:'不需要',
			enName:'Not required',
			type:true
		},
		{
			name:'需要',
			enName:'Need',
			type:true
		}],
		specialProcesses:[{
			name:'金手指',
			enName:'Goldfinger',
			type:true
		},
		{
			name:'半孔工艺',
			enName:'Half hole process',
			type:true
		},
		{
			name:'金属化包边',
			enName:'Metallized edge wrapping',
			type:true
		},
		{
			name:'客户要求叠层',
			enName:'Customer requires layering',
			type:true
		},
		{
			name:'阻抗控制',
			enName:'Impedance control',
			type:true
		},
		{
			name:'沉头孔',
			enName:'Counter bore',
			type:true
		},
		{
			name:'盲埋孔',
			enName:'Blind buried hole',
			type:true
		},
		{
			name:'树脂塞孔',
			enName:'Resin plug hole',
			type:true
		},
		{
			name:'其它',
			enName:'Other',
			type:true
		}],
		invoiceType:[{
			name:'不要发票',
			enName:'No invoice',
			type:true
		},
		{
			name:'普通发票',
			enName:'Ordinary invoice',
			type:true
		},
		{
			name:'增值税发票',
			enName:'VAT invoice',
			type:true
		}],
		invoicingMethod:[{
			name:'累积开票（建议）',
			enName:'Accumulated invoicing (recommended)',
			type:true
		},
		{
			name:'随货电子票（开票金额建议＞200元）',
			enName:'Electronic invoice with goods (recommended invoicing amount>200 yuan)',
			type:true
		},
		{
			name:'随货纸质票（开票金额建议＞200元）',
			enName:'Paper invoice with goods (recommended invoicing amount>200 yuan)',
			type:true
		}],
		NumberFPClayers:[{
			name:'1层',
			enName:'1 layer',
			type:true
		},
		{
			name:'2层',
			enName:'2 layer',
			type:true
		},
		{
			name:'4层',
			enName:'4 layer',
			type:true
		},
		{
			name:'6层',
			enName:'6 layer',
			type:true
		},
		{
			name:'8层',
			enName:'8 layer',
			type:true
		}],
		FPCmaterialQuality:[{
			name:'压延铜',
			enName:'Rolled Copper',
			type:true
		},
		{
			name:'电解铜',
			enName:'Electrolytic Copper',
			type:true
		}],
		FPCBoardThickness:[{
			name:'0.06',
			enName:'0.06',
			type:true
		},
		{
			name:'0.1',
			enName:'0.1',
			type:true
		},
		{
			name:'0.13',
			enName:'0.13',
			type:true
		},
		{
			name:'0.2',
			enName:'0.2',
			type:true
		},
		{
			name:'0.23',
			enName:'0.23',
			type:true
		},
		{
			name:'0.35',
			enName:'0.35',
			type:true
		}],
		FPCCopperThickness:[{
			name:'1/3OZ',
			enName:'1/3OZ',
			type:true
		},
		{
			name:'1/2OZ',
			enName:'1/2OZ',
			type:true
		},
		{
			name:'1OZ',
			enName:'1OZ',
			type:true
		},
		{
			name:'2OZ',
			enName:'2OZ',
			type:true
		}],
		FPCCoveringFilm:[{
			name:'黄色',
			enName:'Yellow',
			color:'#ffd200',
			type:true
		},
		{
			name:'白色',
			enName:'White',
			color:'#fff',
			type:true
		},
		{
			name:'黑色',
			enName:'Black',
			color:'#000',
			type:true
		},
		{
			name:'无',
			enName:'Not',
			color:null,
			type:true
		}],
		FPCCharacterColor:[{
			name:'白色',
			enName:'White',
			color:'#fff',
			type:true
		},
		{
			name:'黑色',
			enName:'Black',
			color:'#000',
			type:true
		},
		{
			name:'无',
			enName:'Not',
			color:null,
			type:true
		}],
		FPCspraying:[{
			name:'沉金',
			enName:'Trumeric',
			value:0,
			type:true,
			list:[]
		},
		{
			name:'OSP',
			enName:'OSP',
			value:0,
			type:true,
			list:[]
		},
		{
			name:'镀硬金',
			enName:'Hard gold plating',
			value:0,
			type:true,
			list:[{
				name:'AU:0.1-0.3U"/NI:20U"',
				enName:'AU:0.1-0.3U"/NI:20U"',
			},
			{
				name:'AU:3U"/NI:120U"',
				enName:'AU:3U"/NI:120U"',
			},
			{
				name:'AU:5U"/NI:120U"',
				enName:'AU:5U"/NI:120U"',
			},
			{
				name:'AU:10U"/NI:120U"',
				enName:'AU:10U"/NI:120U"',
			},
			{
				name:'AU:15U"/NI:120U"',
				enName:'AU:15U"/NI:120U"',
			},
			{
				name:'AU:20U"/NI:120U"',
				enName:'AU:20U"/NI:120U"',
			},
			{
				name:'AU:25U"/NI:120U"',
				enName:'AU:25U"/NI:120U"',
			},
			{
				name:'AU:30U"/NI:120U"',
				enName:'AU:30U"/NI:120U"',
			}]
		}],
		FPCGoldDepositThickness:[{
			name:"1U",
			enName:'1U',
			type:true
		},
		{
			name:"2U",
			enName:'2U',
			type:true
		},
		{
			name:"3U",
			enName:'3U',
			type:true
		}],
		FPCreinforce:[{
			name:'无',
			enName:'Not',
			type:true
		},
		{
			name:'PI补强',
			enName:'PI reinforcement',
			type:true
		},
		{
			name:'钢片补强',
			enName:'Steel sheet reinforcement',
			type:true
		},
		{
			name:'FR4补强',
			enName:'FR4 reinforcement',
			type:true
		}],
		FPCreinforceThickness:[{
			name:'0.025',
			enName:'0.025',
			type:true
		},
		{
			name:'0.0275',
			enName:'0.0275',
			type:true
		},
		{
			name:'0.05',
			enName:'0.05',
			type:true
		},
		{
			name:'0.075',
			enName:'0.075',
			type:true
		},
		{
			name:'0.1',
			enName:'0.1',
			type:true
		},
		{
			name:'0.15',
			enName:'0.15',
			type:true
		},
		{
			name:'0.175',
			enName:'0.175',
			type:true
		},
		{
			name:'0.2',
			enName:'0.2',
			type:true
		},
		{
			name:'0.225',
			enName:'0.225',
			type:true
		},
		{
			name:'0.25',
			enName:'0.25',
			type:true
		},
		{
			name:'0.3',
			enName:'0.3',
			type:true
		}],
		FPCAdhesiveBacking:[{
			name:'无',
			enName:'Not',
			type:true
		},
		{
			name:'3M467 (0.05MM adhesive thickness, not resistant to high temperature)',
			enName:'0.0275',
			type:true
		},
		{
			name:'3M966（0.05MM胶厚，不耐高温）',
			enName:'3M966 (0.05MM adhesive thickness, not resistant to high temperature)',
			type:true
		},
		{
			name:'3M200（0.15MM胶厚，粘性强）',
			enName:'3M200 (0.15MM adhesive thickness, strong adhesion)',
			type:true
		},
		{
			name:'德莎胶纸8854（0.1MM胶耐高温）',
			enName:'Desa adhesive paper 8854 (0.1MM adhesive resistant to high temperature)',
			type:true
		}],
		FPCElectromagneticFilm:[{
			name:'无',
			enName:'Not',
			type:true
		},
		{
			name:'10UM',
			enName:'10UM',
			type:true
		},
		{
			name:'13UM',
			enName:'13UM',
			type:true
		},
		{
			name:'18UM',
			enName:'18UM',
			type:true
		}],
		FPCMinimumLineWidth:[{
			name:'3/3mil',
			enName:'3/3mil',
			type:true
		},
		{
			name:'4/4mil',
			enName:'4/4mil',
			type:true
		},
		{
			name:'5/5mil',
			enName:'5/5mil',
			type:true
		},
		{
			name:'6/6mil',
			enName:'6/6mil',
			type:true
		}],
		FPCMinimumThroughHole:[{
			name:'0.15mm',
			enName:'0.15mm',
			type:true
		},
		{
			name:'0.2mm',
			enName:'0.2mm',
			type:true
		},
		{
			name:'0.25mm',
			enName:'0.25mm',
			type:true
		},
		{
			name:'0.3mm',
			enName:'0.3mm',
			type:true
		}],
		SMTProcessRequirements:[{
			name:'有铅',
			enName:'Lead present',
			type:true
		},
		{
			name:'无铅',
			enName:'Unleaded',
			type:true
		}],
		steelMeshType:[{
			name:'SMT激光钢网',
			enName:'SMT laser steel mesh',
			type:true
		},
		{
			name:'阶梯钢网',
			enName:'Stepped steel mesh',
			type:true
		},
		{
			name:'双工业钢网',
			enName:'Dual industrial steel mesh',
			type:true
		}],
		steelMeshSpecifications1:[{
			name:'37.0cm x 47.0cm(有效面积（19cm x 29cm）--100元',
			enName:'37.0cm x 47.0cm (effective area (19cm x 29cm) -100 yuan',
			id:1,
			type:true
		},
		{
			name:'42.0cm x 52.0cm(有效面积（24cm x 34cm）--120元',
			enName:'42.0cm x 52.0cm (effective area (24cm x 34cm) -120 yuan',
			id:2,
			type:true
		},
		{
			name:'45.0cm x 55.0cm(有效面积（27cm x 37cm）--190元',
			enName:'45.0cm x 55.0cm (effective area (27cm x 37cm) -190 yuan',
			id:3,
			type:true
		},
		{
			name:'58.4cm x 58.4cm(有效面积（38cm x 38cm）--200元',
			enName:'58.4cm x 58.4cm (effective area (38cm x 38cm) -200 yuan',
			id:4,
			type:true
		},
		{
			name:'55.0cm x 65.0cm(有效面积（38cm x 45cm）--210元',
			enName:'55.0cm x 65.0cm (effective area (38cm x 45cm) -210 yuan',
			id:5,
			type:true
		},
		{
			name:'73.6cm x 73.6cm(有效面积（50cm x 50cm）--260元',
			enName:'73.6cm x 73.6cm (effective area (50cm x 50cm) -260 yuan',
			id:6,
			type:true
		},
		{
			name:'40.0cm x 60.0cm(有效面积（22cm x 40cm）--150元',
			enName:'40.0cm x 60.0cm (effective area (22cm x 40cm) -150 yuan',
			id:7,
			type:true
		},
		{
			name:'40.0cm x 80.0cm(有效面积（22cm x 60cm）--180元',
			enName:'40.0cm x 80.0cm (effective area (22cm x 60cm) -180 yuan',
			id:8,
			type:true
		},
		{
			name:'40.0cm x 100.0cm(有效面积（22cm x 76cm）--190元',
			enName:'40.0cm x 100.0cm (effective area (22cm x 76cm) -190 yuan',
			id:9,
			type:true
		},
		{
			name:'28.0cm x 38.0cm无铝框钢片(有效面积（19cm x 29cm）--80元',
			enName:'28.0cm x 38.0cm aluminum free frame steel sheet (effective area (19cm x 29cm) -80 yuan',
			id:10,
			type:true
		},
		{
			name:'50.0cm x 80.0cm(有效面积（32cm x 60cm）--200元',
			enName:'50.0cm x 80.0cm (effective area (32cm x 60cm) -200 yuan',
			id:11,
			type:true
		},
		{
			name:'40.0cm x 120.0cm(有效面积（22cm x 100cm）--210元',
			enName:'40.0cm x 120.0cm (effective area (22cm x 100cm) -210 yuan',
			id:12,
			type:true
		},
		{
			name:'40.0cm x 140.0cm(有效面积（22cm x 120cm）--240元',
			enName:'40.0cm x 140.0cm (effective area (22cm x 120cm) -240 yuan',
			id:13,
			type:true
		},
		{
			name:'50.0cm x 120.0cm(有效面积（32cm x 100cm）--230元',
			enName:'50.0cm x 120.0cm (effective area (32cm x 100cm) -230 yuan',
			id:14,
			type:true
		},
		{
			name:'50.0cm x 140.0cm(有效面积（32cm x 120cm）--260元',
			enName:'50.0cm x 140.0cm (effective area (32cm x 120cm) -260 yuan',
			id:15,
			type:true
		}],
		steelMeshSpecifications2:[{
			name:'37.0cm×47.0cm(阶梯钢网)(有效面积(19cm x 29cm)--260元',
			enName:'37.0cm × 47.0cm (stepped steel mesh) (effective area (19cm x 29cm) -260 yuan',
			id:1,
			type:true
		},
		{
			name:'42.0cm×52.0cm(阶梯钢网)(有效面积(24cm x 34cm)--300元',
			enName:'42.0cm × 52.0cm (stepped steel mesh) (effective area (24cm x 34cm) -300 yuan',
			id:2,
			type:true
		},
		{
			name:'55.0cm×65.0cm(阶梯钢网)(有效面积(35cm x 45cm)--430元',
			enName:'55.0cm × 65.0cm (stepped steel mesh) (effective area (35cm x 45cm) -430 yuan',
			id:3,
			type:true
		},
		{
			name:'58.4cm×58.4cm(阶梯钢网)(有效面积(38cm x 38cm)--420元',
			enName:'58.4cm × 58.4cm (stepped steel mesh) (effective area (38cm x 38cm) -420 yuan',
			id:4,
			type:true
		},
		{
			name:'73.6cm×73.6cm(阶梯钢网)(有效面积(50cm x 50cm)--500元',
			enName:'73.6cm × 73.6cm (stepped steel mesh) (effective area (50cm x 50cm) -500 yuan',
			id:5,
			type:true
		},
		{
			name:'45cm×45cm(阶梯钢网)(有效面积(45cm x 55cm)--380元',
			enName:'45cm × 45cm (stepped steel mesh) (effective area (45cm x 55cm) -380 yuan',
			id:6,
			type:true
		},
		{
			name:'40cm×60cm(阶梯钢网)(有效面积(40cm x 60cm)--380元',
			enName:'40cm × 60cm (stepped steel mesh) (effective area (40cm x 60cm) -380 yuan',
			id:7,
			type:true
		}],
		steelMeshSpecifications3:[{
			name:'37.0cm×47.0cm(双工艺钢网)(有效面积(19cm x 29cm)--360元',
			enName:'37.0cm × 47.0cm (dual process steel mesh) (effective area (19cm x 29cm) -360 yuan',
			id:1,
			type:true
		},
		{
			name:'42.0cm×52.0cm(双工艺钢网)(有效面积(24cm x 34cm)--400元',
			enName:'42.0cm × 52.0cm (dual process steel mesh) (effective area (24cm x 34cm) -400 yuan',
			id:2,
			type:true
		},
		{
			name:'55.0cm×65.0cm(双工艺钢网)(有效面积(35cm x 45cm)--630元',
			enName:'55.0cm × 65.0cm (dual process steel mesh) (effective area (35cm x 45cm) -630 yuan',
			id:3,
			type:true
		},
		{
			name:'58.4cm×58.4cm(双工艺钢网)(有效面积(38cm x 38cm)--620元',
			enName:'58.4cm × 58.4cm (dual process steel mesh) (effective area (38cm x 38cm) -620 yuan',
			id:4,
			type:true
		},
		{
			name:'73.6cm×73.6cm(双工艺钢网)(有效面积(50cm x 50cm)--720元',
			enName:'73.6cm × 73.6cm (dual process steel mesh) (effective area (50cm x 50cm) -720 yuan',
			id:5,
			type:true
		}],
		steelMeshProductionMethod:[{
			name:"顶层",
			enName:'Top layer',
			type:true
		},
		{
			name:"底层",
			enName:'Bottom layer',
			type:true
		},
		{
			name:"顶层+底层（做一个钢网上）",
			enName:'Top layer + bottom layer (making a steel mesh)',
			type:true
		},
		{
			name:"顶层+底层（分开做2个钢网）",
			enName:'Top layer + bottom layer (separated into 2 steel mesh)',
			type:true
		}],
		MARKneed:[{
			name:'不需要',
			enName:'Not required',
			type:true
		},
		{
			name:'半刻',
			enName:'Half a quarter',
			type:true
		},
		{
			name:'通孔',
			enName:'Through-hole',
			type:true
		}],
		steelMeshUsage:[{
			name:"锡膏网",
			enName:'Solder paste mesh',
			type:true
		},
		{
			name:"红胶网",
			enName:'Red glue mesh',
			type:true
		}],
		steelMeshThickness:[{
			name:"0.1mm",
			enName:'0.1mm',
			type:true
		},
		{
			name:"0.12mm",
			enName:'0.12mm',
			type:true
		},
		{
			name:"0.15mm",
			enName:'0.15mm',
			type:true
		},
		{
			name:"0.2mm",
			enName:'0.2mm',
			type:true
		},
		{
			name:"我司自行决定",
			enName:'Our company will decide on its own',
			type:true
		}],
		polishingProcess:[{
			name:"打磨抛光（不收费）",
			enName:'Polishing and polishing (no charge)',
			type:true
		},
		{
			name:"电解抛光（每个钢网需加收50元加工费）",
			enName:'Electrolytic polishing (each steel mesh requires an additional processing fee of 50 yuan)',
			type:true
		}],
		purchaseOnBehalf:[{
			name:"代购",
			enName:'Purchase on behalf',
			type:true
		},
		{
			name:"自供",
			enName:'Confess',
			type:true
		},
		{
			name:"部分代购",
			enName:'Partial purchasing',
			type:true
		}],
		conformalCoating:[{
			name:"不需要",
			enName:'Not require',
			type:true
		},
		{
			name:"单面",
			enName:'Single sided',
			type:true
		},
		{
			name:"双面",
			enName:'Two sided',
			type:true
		}]
	}
	return rows;
}